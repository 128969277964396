.unspacedLabel img {
  width: 30px !important;
}

.unspacedLabel,
.spacedLabel {
  display: flex;
  align-items: center;
  overflow: visible;
  width: max-content;
}

.spacedLabel img {
  max-width: 30px !important;
}

.spacedLabel {
  font-size: 13px;
  padding: 3px;
  gap: 7px
}

span {
  padding-left: 10px;
}
