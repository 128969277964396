@import '../../styles/customMediaQueries.css';

.root {
   margin: 0 auto;
   margin-top: -250px;
   max-width: 300px;

   @media (--viewportMedium) {
      margin-top: -660px;
      margin-left: 50%;
      max-width: unset;
   }

   @media (--viewportLarge) {
      margin-top: -660px;
      margin-left: 32%;
      max-width: unset;
   }
}

.react-multi-carousel-track li {
   width: 300px !important;
}

li button {
   background-color: var(--matterColorAnti);
}

ul :global(.react-multi-carousel-dot--active) button {
   background-color: var(--primaryColor) !important;

   @media (--viewportMedium) {
      background-color: var(--matterColorLight) !important;
   }
}

:global(.react-multi-carousel-dot) button {
   border: none !important;
}

:global(.react-multi-carousel-list) {
   padding-bottom: 16px !important;
}
:global(.react-multi-carousel-track) {
   padding-bottom: 16px !important;
}

:global(.react-multi-carousel-dot-list) {
   margin: 36px;
   bottom: 8px !important;
}

.night {
   background-color: var(--matterColorDark);
}
