@import '../../styles/customMediaQueries.css';

.someLinks {
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  @media (--viewportMedium) {
    gap: 24px;
  }
}

.copyright {
  display: flex;
  margin-top: 24px;
  padding: 8px;
  @media (--viewportMedium) {
    margin-bottom: 24px;
    padding: 12px 60px;
    justify-content: space-between;
  }
}

.night {
  background-color: var(--matterColorDark);
}

.contact {
  padding: 8px 16px;
  @media (--viewportMedium) {
    padding: 12px 70px;
  }
}
