@import '../../styles/customMediaQueries.css';

.root {
  font-family: var(--fontFamily);
}
.wrapper {
  margin: 0 24px 48px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: 0 24px 0px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1360px;
    padding: 0 36px 0 36px;
    margin: 0 auto 0px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1360px;
    padding: 0 36px 0 36px;
    margin: 0 auto 0px auto;
  }
}
.night {
  background-color: var(--matterColorDark) !important;
}
.night .container {
  background-color: var(--matterColorDark) !important;
}
hr.divider {
  margin: 0;
  border: 0;
  height: 2px;
  background: var(--matterColorNegative);
}
.night hr.divider {
  display: none;
}
::selection {
  background: var(--primaryColorLight) !important;
}
