.label {
  width: 100px;
  height: 50px;
  position: relative;
  display: block;
  background: #ebebeb;
  border-radius: 300px;
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4), inset 0px -5px 15px rgba(255, 255, 255, 0.4);
  cursor: pointer;
}

.label:after {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  left: 10px;
  background: linear-gradient(300deg, #ffcc89, #d8860b);
  border-radius: 30px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.input {
  width: 0;
  height: 0;
  visibility: hidden;
}

.input:checked+.label {
  background: #242424;
}

.input:checked+.label:after {
  left: 90px;
  transform: translateX(-100%);
  background: linear-gradient(180deg, #777, #3a3a3a);
}

.label,
.label:after {
  transition: 0.3s
}

.label:active:after {
  width: 60px;
}

.toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.label svg {
  position: absolute;
  width: 18px;
  top: 15px;
  z-index: 100;
}
