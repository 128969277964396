@import '../../styles/customMediaQueries.css';

.strategyContent {
  padding: 48px 24px 48px 24px;
  display: flex;
  flex-direction: column-reverse;
  place-items: center;
  background-color: var(--matterColorLight);
  border-radius: 8px;
  color: var(--matterColor);

  @media (--viewportMedium) {
    padding: 96px 0px 96px 0px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.leftSection {
  display: flex;
  place-items: center;
  width: 100%;

  @media (--viewportMedium) {
    justify-content: flex-end;
    width: 45%;
  }
}

.leftSection img {
  width: 99%;
  border-radius: 20px;

  @media (--viewportMedium) {
    width: 70%;
  }
}

.rightSection {
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100%;

  @media (--viewportMedium) {
    width: 55%;
    align-items: flex-start;
    padding: 0% 6.5rem;
  }
}

.rightSection h1 {
  font-size: 2.5rem;
  letter-spacing: 0.7px;
  color: var(--matterColorHaze);
  line-height: 48px;
}

.rightSection p {
  line-height: 1.6rem;
  font-weight: 400;
  margin-top: 16px;
  padding-bottom: 40px;
  font-size: 16px;

  @media (--viewportMedium) {
    padding-bottom: 0px;
    font-size: 16px;
    max-width: 450px;
  }
}

.night,
.night h1,
.night .rightSection p {
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);
}
