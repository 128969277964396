@import '../../styles/customMediaQueries.css';

.solutionContent {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 96px 24px;
   @media (--viewportMedium) {
      flex-direction: row;
      justify-content: center;
      max-width: 100%;
      padding: 96px;
   }
}

.solutionImage {
   height: auto;
   width: 100%;
   margin-top: 48px;

   @media (--viewportMedium) {
      height: 350px;
      width: auto;
      margin-top: 0px;
   }
}

.mainTitle {
   font-size: 2.5rem;
   letter-spacing: 0.7px;
   margin-bottom: 2.5rem;
   color: var(--matterColorHaze);
   line-height: 48px;
}

.subTitle {
   line-height: 1.6rem;
   font-weight: 400;
   font-style: normal;
}

.night .m,
.night .subTitle,
.night .mainTitle,
.night {
   color: var(--matterColorLight);
   background-color: var(--matterColorDark);
}
