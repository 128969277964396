@import '../../styles/customMediaQueries.css';

.staticsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 0px;
  background-color: var(--primaryColor);
  border-radius: 8px;
  color: var(--matterColorLight);
}

.staticsContent .mainTitle {
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 2.5rem;
  letter-spacing: 0.7px;
  line-height: 48px;
  text-align: center;
}

.subTitle {
  margin-bottom: 80px;
  text-align: center;
  line-height: 2rem;
  font-size: 17px;
  letter-spacing: 0.7px;
  font-weight: 300;
  max-width: 98%;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    max-width: 50%;
  }
}

.stats {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 30px 70px;
  margin-bottom: -130px;
  border-radius: 14px;
  border: 2px solid var(--matterColorNegative);
  background-color: var(--matterColorLight);
  color: var(--matterColorHaze);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-evenly;
    padding-right: 0px;
    min-width: 85%;
  }
}

.night .stats {
  border: 1px solid var(--matterColorAntiDark);
}

.stats .stat {
  display: flex;
  flex-direction: column;
  place-items: center;

  @media (--viewportMedium) {
    justify-content: left;
    align-items: flex-start;
  }
}

.stats img {
  width: 35px;
}

.stats .count {
  margin: 0px;
  font-size: 2.5rem;
  font-weight: var(--fontWeightSemiBold);
}

.verticalDivider {
  width: 140px;
  background-color: var(--matterColorNegative);
  height: 2px;
  margin-block: 20px;

  @media (--viewportMedium) {
    height: 140px;
    width: 2px;
    margin-block: 0px;
  }
}

.night .verticalDivider {
  background-color: var(--matterColorAntiDark);
}

.stat p {
  font-weight: 400;
  font-size: 16px;
}

.night .stats,
.night .stat p {
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);
}
