@import '../../styles/customMediaQueries.css';

.testimonialsTextContent {
   border-radius: 20px;
   border: 1px solid var(--primaryColor);
   background-color: var(--primaryColor);
   color: var(--matterColorLight);
   padding: 48px;
   width: 100%;
   margin-top: 60px;

   @media (--viewportMedium) {
      width: 88%;
      min-height: 600px;
      padding: 96px 48px;
   }
}

.testimonialsTextContent h2,
.testimonialsTextContent p {
   transform: translate(0rem, -6rem);
   color: var(--matterColorLight);

   @media (--viewportMedium) {
      transform: translate(0rem, -4rem);
      max-width: 300px;
   }
}

.testimonialsContent {
   color: var(--matterColorHaze);
   display: flex;
   padding: 96px 24px;
   flex-direction: column;
   justify-content: flex-start;
   align-items: left;
   margin: 0 auto;

   @media (--viewportMedium) {
      padding: 96px;
   }
}

.testimonialsContent h1 {
   font-size: 2.5rem;
   letter-spacing: 0.7px;
   font-weight: 700;
   color: var(--matterColorHaze);
   /* margin-bottom: 7rem; */
   text-align: center;

   @media (--viewportMedium) {
      /* margin-bottom: 2rem; */
   }
}

.testimonialsContent{
   /* transform: translate(0rem, -6rem); */
   border-radius: 50%;
}
.commaImg {
   transform: translate(0rem, -7rem);
   border-radius: 50%;
   height: 120px;
   width: auto;
   @media (--viewportMedium) {
      transform: translate(0rem, -10rem);
   }
}

.testimonialsContent h2 {
   font-size: 2.1rem;
   line-height: 2.3rem;
   letter-spacing: 0.7px;
}

.testimonialsContent p {
   font-size: 15px;
   line-height: 1.5rem;
   font-weight: 400;
   margin-bottom: 10px;

   @media (--viewportMedium) {
      max-width: 250px;
   }
}

.night,
.night h1 {
   background-color: var(--matterColorDark);
   color: var(--matterColorLight) !important;
}
