@import '../../styles/customMediaQueries.css';

.colFlex,
.rowFlex {
   margin-top: 25px;
   display: flex;
   align-items: center;
   flex-direction: row;
   width: 97%;
   height: 200px;
   justify-content: space-between;
   padding: 20px 30px;
   gap: 10px;
   border-radius: 20px;

   @media (--viewportMedium) {
      gap: 20px;
   }
}

.serviceImg img {
   width: auto;
   height: 96px;
}

.serviceIntro {
   font-size: 13px;
   font-weight: 200;
   line-height: 18px;
   margin-block: 8px !important;
   max-width: 150px;

   @media (--viewportMedium) {
      line-height: 22px !important;
   }
}

.titleLetter {
   padding: 3px 7px;
   background-color: var(--matterColorLight);
   color: var(--PrimaryColorDark);
   font-size: 1.2rem;
   width: 30px;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   height: 30px;
   border-radius: 7px;
   font-weight: 700;
   margin: 2px;

   @media (--viewportMedium) {
      width: 45px;
      height: 45px;
      font-size: 1.7rem;
   }
}

.serviceTitle {
   display: flex;
   flex-direction: row;
   gap: 2px;
}

.serviceTitle div {
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.colFlex .serviceTitle {
   flex-direction: column;
   gap: 10px;
}

.serviceTitle div {
   flex-direction: row;
   gap: 2px;
}

.serviceIntro {
   color: var(--PrimaryColorDark);
}

.subFlex {
   display: flex;
   flex-direction: column;
   gap: 5px;

   @media (--viewportMedium) {
      flex-direction: row;
      gap: 20px;
   }
}
