@import '../../styles/customMediaQueries.css';

.technologyIcons {
   display: flex;
   align-items: baseline;
   flex-wrap: wrap;
   font-size: 16px;
   justify-content: center;
   margin-top: 50px;
   padding: 0px 10px;
   grid-gap: 36px 24px;

   @media (--viewportMobile) {
      padding: 0px 100px;
      grid-gap: 84px 24px;
   }
}

.technologyIcon {
   margin-bottom: 15px;
   border-radius: 15px;
   padding: 20px;
   background-color: var(--matterColorNegative);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.night {
   & .technologyIcon {
      background-color: transparent;
   }
}

.technologyIcon img {
   width: auto;
   height: 80px;
}

.technologyName{
   margin-top: 48px;
   /* white-space: nowrap; */
}
