@import '../../styles/customMediaQueries.css';

.root {
  padding: 96px 24px;
  @media (--viewportMedium) {
    padding: 148px 96px;
  }
}

.heroContent {
  box-sizing: border-box;
  display: flex;
  place-items: center;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center
  }
}

.heroSectionRight{
  width: 100%;

  @media (--viewportMedium) {
    width: 60%;
  }
}

.heroSectionLeft {
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100%;
  @media (--viewportMedium) {
    align-items: flex-start;
    width: 40%;
  }
}

.heroTitle {
  margin: 0;
  color: var(--matterColorHaze);
  font-size: 3.5rem;
  line-height: 1.2;
  font-weight: bolder;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.heroSubTitle {
  color: var(--primaryColor);
}

.sectionHeroIntro {
  color: var(--matterColorAntiLite);
  line-height: 1.6rem;
  font-weight: 400;
  font-style: normal;
  width: 100%;
  /* padding: 24px; */
  text-align: center;
  font-size: 16px;
  margin: 24px 0px;

  @media (--viewportMedium) {
    text-align: left;
    padding: 0;
    font-size: 18px;
  }
}

.heroSectionImg {
  width: 100%;
  height: auto;
  margin-top: 48px;
  @media (--viewportMedium) {
    width: 100%;
    height: auto;
    margin-top: 0px;
  }
}

.sectionHeroTalkButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.sectionHeroTalkButton:hover {
  background-color: var(--primaryColorDark);
}

.night .heroTitle,
.night .sectionHeroIntro {
  color: var(--matterColorLight);
}

.night {
  background-color: var(--matterColorDark) !important;
}
