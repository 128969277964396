@import '../../styles/customMediaQueries.css';

.css-1s2u09g-control, .css-1pahdxg-control
{
   border: none !important;
   height: 48px;
   /* box-shadow: none !important; */
   padding-left: 6px;
   padding-right: 6px;
   margin-right: 0px;
   outline: none !important;
   background-color: transparent !important;
   @media (--viewportMedium) {
      margin-right: 20px;
   }
}
.css-b62m3t-container
{
   display: flex;
   justify-content: center;
   height: 100%;
   align-items: center;
}
.css-6j8wv5-Input
{
   pointer-events: none;
}