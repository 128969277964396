@import '../../styles/customMediaQueries.css';

.root {
   overflow: hidden;
   background-color: var(--matterColorLight);
   margin: 10px auto;
   margin-block: 20px;
   max-width: 260px;
   border-radius: 20px;
   @media (--viewportMedium) {
      max-width: 280px;
      margin: 10px;
   }
}

.cardImg {
   width: auto;
   height: 150px;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
}

.cardImg img {
   width: 100%;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
}

.commaImg {
   transform: translate(0rem, -8px);
   border-radius: 50%;
   max-width: 60px;
   @media (--viewportMedium) {
      transform: translate(0rem, 2px);
   }
}

.testimonialContent {
   border-bottom-left-radius: 20px;
   border-bottom-right-radius: 20px;
   padding: 0 8px 24px 8px;
   color: var(--matterColorHaze);
}

.testimonialContent p {
   font-size: 13px;
   line-height: 1.2rem;
   margin-bottom: 10px;
   max-width: 240px;
   font-weight: 400;
}

.testimonial {
   padding-top: 20px;
}

.title {
   font-weight: 900;
   margin: 0;
   padding-bottom: 0;
   font-size: 1.5rem !important;
}

.designation {
   padding-top: 0;
   margin-top: 0;
}
