svg.sun {
  left: 16px;
  transition: 0.3s;
}

svg.dark {
  fill: #7e7e7e;
}

svg.light {
  fill: #fff;
}
