@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 96px;
}

.form {
  margin-top: 50px;
}

.row {
  padding-bottom: 24px;
}

.recaptcha iframe {
  transform: scale(0.83);
  -webkit-transform: scale(0.83);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;

  @media (--viewportMedium) {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.row,
input[type=text],
textarea,
.select,
.phoneSelect input {
  font-weight: 300;
  font-size: 16px;
  width: 100%;
}

textarea {
  height: 67px;
}

textarea::-webkit-scrollbar {
  display: none;
}

.phoneSelect input {
  margin-left: -44px;
  text-indent: 45px;
}

.form label {
  font-weight: 400;
  font-size: 16px;
  margin: 8px 0px;
}

.select,
.form input,
.form textarea {
  padding: 16px 0px;
  border: none;
  border-bottom: 2px solid var(--matterColorNegative);
  font-size: 1.125rem;
  box-sizing: border-box;
}

.night .select,
.night .form input,
.night .form textarea {
  border-bottom: 1px solid var(--matterColorAntiDark);
}

.select:hover,
input:hover,
textarea:hover {
  outline: none;
  border-bottom: 2px solid var(--primaryColorLight) !important;
}

.select:active,
.select:focus,
input:active,
input:focus,
textarea:focus,
textarea:active {
  outline: none;
  border-bottom: 2px solid var(--primaryColor) !important;
}

.checkBox {
  display: flex;
  align-items: baseline;
  margin-block: 8px;
}

.checkBox input {
  max-width: 30px !important;
}

::placeholder,
option,
option:checked,
select {
  font-size: 1rem;
  font-weight: 300;
}

.form button {
  border: none;
  background-color: var(--primaryColor);
  border-radius: 5px;
  padding: 13px 40px;
  color: var(--matterColorLight);
  cursor: pointer;
  font-weight: normal;
  font-size: 1rem;
  width: 252px;
  @media (--viewportMedium) {
    width: 305px;
  }
}

.letsTalkHead {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
  }
}

.letsTalkHead img {
  height: 165px;
  width: auto;
  @media (--viewportMedium) {
    height: 350px;
  }
}

.letsTalkHead h1 {
  font-size: 2.5rem;
  letter-spacing: 0.7px;
  color: var(--matterColorHaze);
  @media (--viewportMedium) {
    margin-bottom: -96px;
  }
}

.letsTalkContent {
  padding: 0px 24px;

  @media (--viewportMedium) {
    padding: 0px 60px;
  }
}

.row span {
  color: var(--failColor);
}

.night ::placeholder,
.night h1,
.night .form,
.night .select,
.night .phoneSelect option {
  color: var(--matterColorLight);
  background-color: var(--matterColorDark);
}

.error {
  color: var(--failColor);
}

.night {
  background-color: var(--matterColorDark);
}