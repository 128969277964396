@import '../../styles/customMediaQueries.css';

.technologiesContent h1 {
   font-size: 2.5rem;
   letter-spacing: 0.7px;
   color: var(--matterColorHaze);
   margin-bottom: 2rem;
   text-align: center;
   line-height: 48px;
}

.technologiesContent {
   padding: 0 24px;

   &:global(.react-tabs__tab-list) {
      display: flex !important;
      flex-direction: row;
      align-items: center;
      justify-content: space-between !important;
      font-family: var(--fontFamily) !important;
      font-size: 1.2rem;
      gap: 50px;

      @media (--viewportMedium) {
         gap: 10px !important;
         grid-gap: 10px !important;
      }
   }

   &:global(.react-tabs__tab--selected) {
      background: none !important;
      border-top: none;
      border-left: none;
      border-right: none;
      color: var(--primaryColor) !important;
      border-bottom: 2.5px solid var(--primaryColor) !important;
   }

   @media (--viewportMedium) {
      padding: 0 96px;
   }
}

.night,
.night h1 {
   color: var(--matterColorLight);
}

.night .react-tabs__tab--selected {
   color: var(--matterColorBright) !important;
}

.night {
   background-color: var(--matterColorDark);

   :global(.react-tabs__tab--selected) {
      color: var(--matterColorBright) !important;
   }
}

:global(.react-tabs__tab--selected) {
   background: none !important;
   border: none !important;
   color: var(--primaryColor) !important;
   border-bottom: 2.5px solid var(--primaryColor) !important;
}

:global(.react-tabs__tab-list) {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   font-family: var(--fontFamily) !important;
   font-size: 1.2rem;
   gap: 20px;
   grid-gap: 0px;

   @media (--viewportMedium) {
      justify-content: center;
      gap: 50px;
      grid-gap: 50px;
   }
}
