@import '../../styles/customMediaQueries.css';

.whatWeDoContent {
   padding: 96px 24px;
   color: var(--matterColorHaze);

   @media (--viewportMedium) {
      padding: 96px;
   }
}

.services {
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   @media (--viewportLarge) {
      flex-direction: row;
   }
}

.serviceIntro {
   font-size: 13px;
   font-weight: 200;
   max-width: 150px;
}

.serviceImg img {
   max-width: 100%;
   max-height: 150px;
}

.block {
   @media (--viewportLarge) {
      width: 47%;
   }
}

.titles {
   display: flex;
   flex-direction: column;
   place-items: center;
}

.titleLetter {
   padding: 3px 7px;
   font-size: 1.7rem;
   width: 45px;
   display: flex;
   place-items: center;
   height: 45px;
   border-radius: 8px;
   font-weight: 700;
   margin: 2px;
   background-color: var(--matterColorLight);
   color: var(--PrimaryColorDark);
}

.serviceTitle {
   display: flex;
   gap: 2px;
}

.serviceTitle div {
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.serviceIntro {
   color: var(--failColorLight);
}

.dev .serviceIntro,
.qa .serviceIntro {
   min-width: 250px;
}

.uiUx .serviceIntro {
   min-width: 150px;
}

.subTitle {
   max-width: 550px;
   line-height: 1.8rem;
   font-weight: 400;
   font-style: normal;
   text-align: center;
}

.mainTitle {
   font-size: 2.5rem;
   letter-spacing: 0.7px;
   line-height: 48px;
}

.night .mainTitle,
.night .subTitle {
   color: var(--matterColorLight);
   background-color: var(--matterColorDark);
}

.night {
   background-color: var(--matterColorDark);
}
